
export const EWB_TENANT_MAP = {
  TITLE: 'E-WorkBook',
  HELP_LINK: 'https://help.idbs.com/UI_landing/E-WorkBook_accessory_files/index.html'
}
Object.freeze(EWB_TENANT_MAP)
export const POLAR_TENANT_MAP = {
  TITLE: 'Polar',
  HELP_LINK: 'https://help.idbs.com/UI_landing/Polar_accessory_files/index.html'
}
Object.freeze(POLAR_TENANT_MAP)
export const CONFIG_SERVICE_QUERY_TIMEOUT_MS = 10000
export const IDBS_CLOUD_DOMAIN = 'idbs-cloud.com'
export const IDBS_DEV_DOMAIN = 'idbs-dev.com'
export const IDBS_STAGING_DOMAIN = 'idbs-staging.com'
export const SAVE_TOASTER_ID = 'save-toaster'
export const TOASTER_TIMEOUT_MS = 10000
export const ROLE_ARN_REGEX = /^arn:aws:iam::(\d{12})?:role\/[\w+=,.@-]{1,128}$/
export const ROLE_NAMING_LINK = 'https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_iam-quotas.html#reference_iam-quotas-names'
export const BUCKET_NAMING_LINK = 'https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html#general-purpose-bucket-names'
export const BUCKET_NAME_REGEX = /^[A-Za-z0-9][A-Za-z0-9\-.]{1,61}[A-Za-z0-9]$/
